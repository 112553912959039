export const IS_EMBED = window !== window.top;

module myUtil {
  export const formatNumber = (
    x: number | string,
    fractionDigits: number = 2,
    alwaysShowDecimalPoint: boolean = false
  ): string => {
    x = (+x).toFixed(fractionDigits);
    if (!alwaysShowDecimalPoint) x = parseFloat(x.toString());
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    var output = parts.join(".");
    // console.log(output);
    return output;
  };

  export const prepareFloatingNumber = (
    value: number,
    digit: number = 10
  ): number => {
    return parseFloat(value.toFixed(digit));
  };

  /**
   *
   * @param value floating values
   * @returns fixed floating values
   * Shortcut functions of prepareFloatingNumber
   */
  export const pfn = (value: number): number => prepareFloatingNumber(value);

  export const toggleDarkTheme = (shouldAdd: boolean) => {
    console.log(shouldAdd);
    document.body.classList.toggle("dark", shouldAdd);
  };

  export const setupDefaultDarkMode = () => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    toggleDarkTheme(prefersDark.matches);
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) =>
      toggleDarkTheme(mediaQuery.matches)
    );
  };
}

export default myUtil;
