import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { calculatorOutline, flashOutline } from "ionicons/icons";

import BasicPage from "./pages/Basic";
import PowerPage from "./pages/Power";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/custom.css";
import "./theme/variables.css";
import myUtil, { IS_EMBED } from "./helpers/cha-utils";
import {
  App,
} from "@capacitor/app";

setupIonicReact();

myUtil.setupDefaultDarkMode();

const MainApp = () => {

  // const ionRouter = useIonRouter();
  document.addEventListener('ionBackButton', (ev) => {
    // ev.detail.register(-1, () => {
    //   if (!ionRouter.canGoBack()) {
    //     console.log("Try to exit!");
    App.exitApp();
    //   }
    // });
  });

  const tabBarPosition = IS_EMBED
    ? "top"
    : "bottom";
  const tabBarColor = IS_EMBED ? "primary" : "";
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/basic">
              <BasicPage />
            </Route>
            <Route exact path="/power">
              <PowerPage />
            </Route>
            <Route exact path="/">
              <Redirect to="/basic" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot={tabBarPosition} color={tabBarColor}>
            <IonTabButton tab="basic" href="/basic">
              <IonIcon aria-hidden="true" icon={calculatorOutline} />
              <IonLabel>Basic Ω</IonLabel>
            </IonTabButton>
            <IonTabButton tab="power" href="/power">
              <IonIcon aria-hidden="true" icon={flashOutline} />
              <IonLabel>Find Power</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default MainApp;
