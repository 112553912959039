import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import { moon } from "ionicons/icons";
import myUtil from "../helpers/cha-utils";

const Sidebar: React.FC = () => {
  // Add or remove the "dark" class based on if the media query matches

  return (
    <IonMenu contentId="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Ohm's Law Calculator</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem lines="full">
            <IonIcon slot="start" icon={moon}></IonIcon>
            <IonLabel>Toggle Dark Mode</IonLabel>
            <IonToggle
              id="themeToggle"
              slot="end"
              onIonChange={(e) => myUtil.toggleDarkTheme(e.detail.checked)}
            ></IonToggle>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>calculatorhub.app</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Sidebar;
