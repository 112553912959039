import myUtil from "./cha-utils";

export type OhmType = {
  v: number;
  i: number;
  r: number;
};

export type FieldStatType = {
  v: boolean;
  i: boolean;
  r: boolean;
  p: boolean;
};

export interface FieldStatTypeInterface {
  [key:string]: boolean;
}

export type FieldValueType = {
  v: number;
  i: number;
  r: number;
  p: number;
};


module Logic {
  export const calculateOhms = (ohm: OhmType, calculateFiled: string) => {
    // console.log(ohm);
    if (ohm.v === 0 && ohm.r === 0 && ohm.i === 0) return; // return if no value provided.

    switch (calculateFiled) {
      case "i":
        ohm.i = ohm.v / ohm.r;
        ohm.i = myUtil.prepareFloatingNumber(ohm.i);
        break;
      case "v":
        ohm.v = ohm.r * ohm.i;
        ohm.v = myUtil.prepareFloatingNumber(ohm.v);
        break;
      case "r":
        ohm.r = ohm.v / ohm.i;
        ohm.r = myUtil.prepareFloatingNumber(ohm.r);
        break;
    }
    return ohm;
  };

  export const calculateOhmsPair = (
    s: FieldStatTypeInterface,
    val: FieldValueType
  ): FieldValueType | undefined => {
    let v = val.v,
      p = val.p,
      i = val.i,
      r = val.r;

    if (v === 0 || p === 0 || i === 0 || r === 0) return;

    if (s.p && s.r) i = Math.sqrt(p / r);
    if (s.p && s.v) i = p / v;
    if (s.v && s.r) i = v / r;

    if (s.r && s.i) v = r * i;
    if (s.p && s.i) v = p / i;
    if (s.p && s.r) v = Math.sqrt(p * r);

    if (s.v && s.i) r = v / i;
    if (s.v && s.p) r = (v * v) / p;
    if (s.p && s.i) r = p / (i * i);

    if (s.v && s.i) p = v * i;
    if (s.r && s.i) p = r * (i * i);
    if (s.v && s.r) p = (v * v) / r;

    const output: FieldValueType = { v: v, p: p, i: i, r: r };
    return output;
  };


  
  export const getContentLine1 = (
    s: FieldStatTypeInterface,
  ): {i:string, v:string, r:string, p:string} => {
    let v = "",
      p = "",
      i = "",
      r = "";




    if (s.p && s.r) i = "I = √ (P ÷ R)";
    if (s.p && s.v) i = "I = P ÷ V";
    if (s.v && s.r) i = "I = V ÷ R";

    if (s.r && s.i) v = "V = I × R";
    if (s.p && s.i) v = "V = P ÷ I";
    if (s.p && s.r) v = "V = √ (P × R)";

    if (s.v && s.i) r = "R = V ÷ I";
    if (s.v && s.p) r = "R = V² ÷ P";
    if (s.p && s.i) r = "R = P ÷ I²";

    if (s.v && s.i) p = "P = V × I";
    if (s.r && s.i) p = "P = R × I²";
    if (s.v && s.r) p = "P = V² ÷ R";

    return {i, v, r, p}
  };


  export const changeUnit = (
    target: HTMLIonSelectElement,
    oldValue: number,
    oldUnit: string,
    units: customUnit,
    defaultUnit: string
  ): { value: number; unit: string } | undefined => {
    if (target.value === oldUnit) return;
    const oldUnitObject = units[oldUnit];
    const selectedUnitObject = units[target.value];
    let newValue = oldValue;
    if (selectedUnitObject?.unit === defaultUnit) {
      newValue = oldValue / oldUnitObject!.modifier;
    } else {
      newValue = oldValue / oldUnitObject!.modifier; // revert value back its original
      newValue = newValue * selectedUnitObject!.modifier; // apply the new modifier
    }
    newValue = myUtil.prepareFloatingNumber(newValue);
    return { value: newValue, unit: selectedUnitObject!.unit };
  };
}

export default Logic;

type customUnit = {
  [key: string]: {
    unit: string;
    modifier: number;
    text: string;
    desc: string;
  };
};

export const voltageUnits: customUnit = {
  kV: { unit: "kV", modifier: 0.001, text: "kV", desc: "kilo-volts" },
  V: { unit: "V", modifier: 1, text: "V", desc: "volts" },
  mV: { unit: "mV", modifier: 1000, text: "mV", desc: "milli-volts" },
};

export const currentUnits: customUnit = {
  A: { unit: "A", modifier: 1, text: "A", desc: "amps" },
  mA: { unit: "mA", modifier: 1000, text: "mA", desc: "milli-amps" },
};

export const resistanceUnits: customUnit = {
  MΩ: { unit: "MΩ", modifier: 0.000001, text: "MΩ", desc: "mega-ohms" },
  kΩ: { unit: "kΩ", modifier: 0.001, text: "kΩ", desc: "kilo-ohms" },
  Ω: { unit: "Ω", modifier: 1, text: "Ω", desc: "ohms" },
  mΩ: { unit: "mΩ", modifier: 1000, text: "mΩ", desc: "milli-ohms" },
};

export const powerUnits: customUnit = {
  kW: { unit: "kW", modifier: 0.001, text: "kW", desc: "kilo-watts" },
  W: { unit: "W", modifier: 1, text: "W", desc: "watts" },
  mW: { unit: "mW", modifier: 1000, text: "mW", desc: "milli-watts" },
};
