import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Logic, {
  currentUnits,
  OhmType,
  resistanceUnits,
  voltageUnits,
} from "../../helpers/logic";

import { returnDownForward, searchOutline } from "ionicons/icons";
import myUtil, { IS_EMBED } from "../../helpers/cha-utils";

import "./style.css";
import Sidebar from "../../components/Sidebar";

const Index: React.FC = () => {
  const [roundDigit, setRoundDigit] = useState<number>(5);

  const [calculateField, setCalculateField] = useState<string>("r");

  const [voltage, setVoltage] = useState<number>(110);
  const [voltageUnit, setVoltageUnit] = useState<string>("V");
  const voltageInput = myUtil.pfn(
    voltage * voltageUnits[voltageUnit]!.modifier
  );
  const voltageText = parseFloat(voltage.toFixed(roundDigit));

  const [current, setCurrent] = useState<number>(0.4);
  const [currentUnit, setCurrentUnit] = useState<string>("A");
  const currentInput = myUtil.pfn(
    current * currentUnits[currentUnit]!.modifier
  );
  const currentText = parseFloat(current.toFixed(roundDigit));

  const [resistance, setResistance] = useState<number>(275);
  const [resistanceUnit, setResistanceUnit] = useState<string>("Ω");
  const resistanceInput = myUtil.pfn(
    resistance * resistanceUnits[resistanceUnit]!.modifier
  );
  const resistanceText = parseFloat(resistance.toFixed(roundDigit));

  const voltageUnitsJSX = Object.keys(voltageUnits).map((unit) => (
    <IonSelectOption
      key={voltageUnits[unit].modifier}
      value={voltageUnits[unit].unit}
    >
      {voltageUnits[unit].text}
    </IonSelectOption>
  ));
  const currentUnitsJSX = Object.keys(currentUnits).map((unit) => (
    <IonSelectOption
      key={currentUnits[unit].modifier}
      value={currentUnits[unit].unit}
    >
      {currentUnits[unit].text}
    </IonSelectOption>
  ));
  const resistanceUnitsJSX = Object.keys(resistanceUnits).map((unit) => (
    <IonSelectOption
      key={resistanceUnits[unit].modifier}
      value={resistanceUnits[unit].unit}
    >
      {resistanceUnits[unit].text}
    </IonSelectOption>
  ));

  useEffect(() => {
    setRoundDigit(4);
  }, []);
  useEffect(() => {
    const enteredOhm: OhmType = { v: 0, i: 0, r: 0 };

    enteredOhm.v = voltage;
    enteredOhm.r = resistance;
    enteredOhm.i = current;

    const calculatedOhm = Logic.calculateOhms(enteredOhm, calculateField);
    console.log(calculatedOhm);
    if (!calculatedOhm) return;
    setCurrent(calculatedOhm.i);
    setVoltage(calculatedOhm.v);
    setResistance(calculatedOhm.r);
  }, [voltage, current, resistance, calculateField]);

  const changeUnitHandler = (target: HTMLIonSelectElement) => {
    if (target.name === "voltageUnit") {
      const output = Logic.changeUnit(
        target,
        voltage,
        voltageUnit,
        voltageUnits,
        "V"
      );
      if (output) {
        // voltageInput = voltage * voltageUnits[voltageUnit]!.modifier;
        setVoltageUnit(output.unit);
      }
    }
    if (target.name === "currentUnit") {
      const output = Logic.changeUnit(
        target,
        current,
        currentUnit,
        currentUnits,
        "A"
      );
      if (output) {
        // setCurrent(output.value);
        setCurrentUnit(output.unit);
      }
    }
    if (target.name === "resistanceUnit") {
      const output = Logic.changeUnit(
        target,
        resistance,
        resistanceUnit,
        resistanceUnits,
        "Ω"
      );
      if (output) {
        // setResistance(output.value);
        setResistanceUnit(output.unit);
      }
    }
  };

  // console.log("Basic Page Loaded");
  return (
    <>
      {!IS_EMBED && <Sidebar />}

      <IonPage id="main-content">
        {!IS_EMBED && (
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonTitle>Ohm's Law Calculator</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent className="ion-padding">
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonSegment value={calculateField}>
                        <IonSegmentButton
                          value="r"
                          onClick={() => setCalculateField("r")}
                        >
                          <IonIcon icon={searchOutline}></IonIcon>
                          <IonLabel>Resistance</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          value="i"
                          onClick={() => setCalculateField("i")}
                        >
                          <IonIcon icon={searchOutline}></IonIcon>
                          <IonLabel>Current</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          value="v"
                          onClick={() => setCalculateField("v")}
                        >
                          <IonIcon icon={searchOutline}></IonIcon>
                          <IonLabel>Voltage</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem
                        fill="solid"
                        disabled={calculateField !== "v" ? false : true}
                      >
                        <IonLabel position="stacked">Voltage (V)</IonLabel>
                        <IonInput
                          type="number"
                          value={voltageInput}
                          onIonInput={(ev) => {
                            setVoltage(
                              myUtil.prepareFloatingNumber(
                                +ev.target!.value! /
                                  voltageUnits[voltageUnit].modifier
                              )
                            );
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4">
                      <IonItem fill="solid">
                        <IonLabel position="floating">Units</IonLabel>
                        <IonSelect
                          name="voltageUnit"
                          value={voltageUnit}
                          onIonChange={(ev) => changeUnitHandler(ev.target!)}
                        >
                          {voltageUnitsJSX}
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem
                        fill="solid"
                        disabled={calculateField !== "i" ? false : true}
                      >
                        <IonLabel position="stacked">Current (I)</IonLabel>
                        <IonInput
                          type="number"
                          step="0.1"
                          value={currentInput}
                          style={{ fontSize: "1.5rem" }}
                          onIonInput={(ev) =>
                            setCurrent(
                              myUtil.pfn(
                                +ev.target!.value! /
                                  currentUnits[currentUnit].modifier
                              )
                            )
                          }
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4">
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Units</IonLabel>
                        <IonSelect
                          name="currentUnit"
                          value={currentUnit}
                          style={{ fontSize: "1.5rem" }}
                          onIonChange={(ev) => changeUnitHandler(ev.target!)}
                        >
                          {currentUnitsJSX}
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem
                        fill="solid"
                        disabled={calculateField !== "r" ? false : true}
                      >
                        <IonLabel position="stacked">Resistance (R)</IonLabel>
                        <IonInput
                          type="number"
                          value={resistanceInput}
                          disabled={calculateField !== "r" ? false : true}
                          style={{ fontSize: "1.5rem" }}
                          onIonInput={(ev) =>
                            setResistance(
                              myUtil.prepareFloatingNumber(
                                +ev.target!.value! /
                                  resistanceUnits[resistanceUnit].modifier
                              )
                            )
                          }
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4">
                      <IonItem fill="solid">
                        <IonLabel position="floating">Units</IonLabel>
                        <IonSelect
                          name="resistanceUnit"
                          value={resistanceUnit}
                          style={{ fontSize: "1.5rem" }}
                          onIonChange={(ev) => changeUnitHandler(ev.target!)}
                        >
                          {resistanceUnitsJSX}
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonGrid>
                  {/* Voltage Result Card */}
                  {calculateField === "v" && (
                    <IonRow>
                      <IonCol>
                        <IonCard className="ion-no-margin">
                          <IonCardHeader color="primary">
                            <IonCardTitle>
                              {myUtil.formatNumber(voltageInput)} {voltageUnit}
                            </IonCardTitle>
                            <IonCardSubtitle>
                              Voltage V = {voltageInput} {voltageUnit} (
                              {voltageUnits[voltageUnit].desc})
                            </IonCardSubtitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>V = R × I</IonLabel>
                            </IonItem>
                            {(resistanceUnit !== "Ω" ||
                              currentUnit !== "A") && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                <IonLabel>
                                  V = {resistanceInput} {resistanceUnit} ×{" "}
                                  {currentInput}
                                  {currentUnit}
                                </IonLabel>
                              </IonItem>
                            )}
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>
                                V = {resistanceText} Ω × {currentText} A
                              </IonLabel>
                            </IonItem>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>V = {voltage} V</IonLabel>
                            </IonItem>
                            {voltageUnit !== "V" && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                <IonLabel>
                                  V = {voltageInput} {voltageUnit}
                                </IonLabel>
                              </IonItem>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  )}

                  {calculateField === "i" && (
                    <IonRow>
                      <IonCol>
                        <IonCard className="ion-no-margin">
                          <IonCardHeader color="primary">
                            <IonCardTitle>
                              {myUtil.formatNumber(currentInput)} {currentUnit}
                            </IonCardTitle>
                            <IonCardSubtitle>
                              Current I = {currentInput} {currentUnit} (
                              {currentUnits[currentUnit].desc})
                            </IonCardSubtitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>I = V ÷ R</IonLabel>
                            </IonItem>
                            {(resistanceUnit !== "Ω" ||
                              voltageUnit !== "V") && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                I = {myUtil.formatNumber(voltageInput)}{" "}
                                {voltageUnit}
                                {` ÷ `}
                                {myUtil.formatNumber(resistanceInput)}{" "}
                                {resistanceUnit}
                              </IonItem>
                            )}
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>
                                I = {voltageText} V ÷ {resistanceText} Ω
                              </IonLabel>
                            </IonItem>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>I = {current} A</IonLabel>
                            </IonItem>
                            {currentUnit !== "A" && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                <IonLabel>
                                  I = {currentInput} {currentUnit}
                                </IonLabel>
                              </IonItem>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  )}

                  {/* Resistance Card */}
                  {calculateField === "r" && (
                    <IonRow>
                      <IonCol>
                        <IonCard className="ion-no-margin">
                          <IonCardHeader color="primary">
                            <IonCardTitle>
                              {myUtil.formatNumber(resistanceInput)}{" "}
                              {resistanceUnit}
                            </IonCardTitle>
                            <IonCardSubtitle>
                              Resistance R = {resistanceInput} {resistanceUnit}{" "}
                              ({resistanceUnits[resistanceUnit].desc})
                            </IonCardSubtitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>R = V ÷ I</IonLabel>
                            </IonItem>
                            {(voltageUnit !== "V" || currentUnit !== "A") && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                R = {voltageInput} {voltageUnit}
                                {` ÷ `}
                                {currentInput} {currentUnit}
                              </IonItem>
                            )}
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>
                                R = {voltage} V ÷ {current} A
                              </IonLabel>
                            </IonItem>
                            <IonItem className="ion-no-padding">
                              <IonIcon
                                icon={returnDownForward}
                                slot="start"
                              ></IonIcon>
                              <IonLabel>R = {resistance} Ω</IonLabel>
                            </IonItem>
                            {resistanceUnit !== "Ω" && (
                              <IonItem className="ion-no-padding">
                                <IonIcon
                                  icon={returnDownForward}
                                  slot="start"
                                ></IonIcon>
                                <IonLabel>
                                  R = {resistanceInput} {resistanceUnit}
                                </IonLabel>
                              </IonItem>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Index;
